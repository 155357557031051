import { images, url_api_res, url_websocket } from '../constants/constants';
import ButtonCustom from '../components/buttoncustom';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
//import Post from './components/post';

export { ViewFlyer }

const ViewFlyer = () => {

    const { flyer } = useParams();

    return (
      <div className='viewflyer'>
        <img src={"/flyer/"+flyer} />
      </div>
   )
}
