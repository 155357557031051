

export const images = [
    {
      url: '/slider/7_small.png',
      title: 'Rally Kart - Precios',
      action: './viewflyer/flyer7.png'
    },
    {
      url: '/slider/1_small.png',
      title: 'Rally Kart',
      action: './viewflyer/flyer1.png'
    },
    /*{
      url: '/slider/6_small.png',
      title: 'Rally Kart Descuentos Banco de Chile solo por mayo.',
      action: './viewflyer/flyer6.png',
      shedule: '1;1;1;1;0;0;0;'
    },*/
    {
      url: '/slider/2_small.png',
      title: 'Rally Kart',
      action: './viewflyer/flyer2.png'
    },
    {
      url: '/slider/3_small.png',
      title: 'Rally Kart',
      action: 'https://web.racefacer.com/kiosk/belloto'
    },
    {
      url: '/slider/4_small.png',
      title: 'Rally Kart',
      action: './apps_download'
    },
    {
      url: '/slider/5_small.png',
      title: 'Baden Powell 152, Quilpué, Valparaíso',
      action: 'https://www.google.com/maps/place/RALLY+KART/@-33.045263,-71.4235134,17.05z/data=!4m6!3m5!1s0x9689d9a462d511ab:0x5c198fa8bb13f0e1!8m2!3d-33.0465007!4d-71.4213866!16s%2Fg%2F11k4zps55n?entry=ttu'
    },
    {
      url: <div style={{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center', background: "url('/diagrama.jpg')", backgroundRepeat:'no-repeat',backgroundSize:'contain', backgroundPosition:'center center'}}><div style={{position:'absolute',top:'60px', color:'black',fontWeight:'600',textTransform:'capitalize',fontSize:'23px'}}>Diagrama Pista 3D</div></div>,
      title: '',
      action: 'https://sketchfab.com/models/edb0bcef84cd4b7ca48483acbbb8ae76/embed?autospin=1&autostart=1&preload=1'
    }
];

export const url_websocket = 'wss://api-rk.sxztem.net:5000';
export const url_api_res = (process.env.NODE_ENV === 'production' ? 'https://api-rk.sxztem.net/api' : 'http://127.0.0.1:5000/api');