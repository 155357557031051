import IconGoTo from "./icongoto";
import { useNavigate,useLocation  } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = () => {

    const location = useLocation();
    const navigate = useNavigate();
    
    const RenderButtonBack = () => {
        const pathSplit = location.pathname.split('/');

        if(pathSplit[pathSplit.length-1].length <= 0){
            return null;
        }

        return <div className='btn-back' onClick={(e) => { navigate(-1) }}><div><FontAwesomeIcon icon="fas fa-chevron-left" /></div></div>
    } 

    const getSucursal = () => {
        const pathSplit = location.pathname.split('/');
        return pathSplit[1];
    }


    return(
        <div className="header">
            <RenderButtonBack />
            {/*<IconGoTo href="https://www.rallykart.cl" src="/logo.png" className='logo_rk' />*/}
            <a href='/' className='logo_brand'>
                <img src="../../logo.png" />
                <span>{getSucursal()}</span>
            </a>
        </div>
    );
};

export default Header;