import Gallery from 'react-photo-gallery'
import Modal from 'react-modal';
import { useState } from 'react';

export { MediaPage }
const MediaPage = () => {

    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState(null)

    const photos = [
        {
            src: '/post/post_1.jpg',
            width: 1,
            height: 1,
            customElements: <video style={{width:'90%',height:'100%'}} controls autoPlay><source src="/video.mp4" type="video/mp4"/></video>
        },
        {
            src: '/post/post_4.jpg',
            width: 1,
            height: 1
        },
        {
            src: '/post/post_5.jpg',
            width: 1,
            height: 1
        },
        {
            src: '/post/post_7.jpg',
            width: 1,
            height: 1
        },
        {
            src: '/post/post_8.jpg',
            width: 1,
            height: 1
        },
        {
            src: '/post/post_9.jpg',
            width: 1,
            height: 1
        },
        {
            src: '/post/post_10.jpg',
            width: 1,
            height: 1
        },
        {
            src: '/post/post_11.jpg',
            width: 1,
            height: 1
        },
        {
            src: '/post/post_12.jpg',
            width: 1,
            height: 1
        }
    ];

    const modalView = (e, data) => {
        setModal(true);
        setModalContent(data.photo.customElements && data.photo.customElements || <img src={data.photo.src} width={'100%'} height={"100%"} />);
    }

    const renderItem = (data) => {
        return <div onClick={(e) => modalView(e, data)}>
            <img src={data.photo.src} width={data.photo.width} height={data.photo.height} />
        </div>
    }

    return <div>
        <div className='titlebar'>
            <h3 style={{color:'white',textTransform:'uppercase'}}>Media</h3>
        </div>
        <Gallery photos={photos} renderImage={renderItem} />


        <Modal isOpen={modal} onRequestClose={(e) => setModal(false)}  >
            <div style={{display:'flex',justifyContent:'center', Height:'60vh'}}>
                <div style={{backgroundColor:'black', border: '1px solid #AAA',padding:'5px', width:'35px', height:'35px',position:'absolute',right:'0', display:'flex', alignItems:'center', justifyContent:'center', color:'white', fontSize:'25px'}} onClick={(e) => {setModal(false)}}>X</div>
                {modalContent}
            </div>
        </Modal>
    </div>
}