// Import Swiper React components
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import { images, url_api_res, url_websocket } from '../constants/constants';
import ButtonCustom from '../components/buttoncustom';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
//import Post from './components/post';
const moment = require('moment-timezone');

export { Home }

const Home = () => {

    const { sucursal } = useParams();

    moment.locale('es');

    const swiperRef = useRef(null)
    const [status, setStatus] = useState(true);
    const [schedule, setSchedule] = useState('10:00 am - 20:00 pm');

    const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
    const [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait');

    SwiperCore.use([Autoplay]);

    const onWindowResize = () => {              
      clearTimeout(window.resizeLag)
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag                       
        setOrientation(isLandscape() ? 'landscape' : 'portrait')
      }, 200)
    }

    useEffect(() => {
        const CheckRaceStatus = async () => {
          await fetch(`${url_api_res}/race_status`).then((response) => response.json()).then((json) => {
            if(json.success)
            {
              setStatus(json.data.status);
              setSchedule(json.data.schedule);
            }
          }).catch((e) => console.log(e.message));
        };
        CheckRaceStatus();    

        onWindowResize();
        window.addEventListener("resize", onWindowResize);
    },[]);

    const Horarios = ({data}) => { 

      return <div>{data}</div>
    }
    
    return (
      <div onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()} onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}>
        <Swiper
          ref={swiperRef}
          loop={true}
          autoplay={{
              delay: 5000,
              disableOnInteraction: false
          }}
          spaceBetween={20}
          slidesPerView={1}
          //onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => null /*console.log(swiper) */}
          style={{margin:'0 15px',padding:'10px', paddingTop:'15px'}}
        >
          {images.map((image, index) => {

            if(image.shedule != null)
            {
              let momentZone = moment().tz("America/Santiago");
              let currentDay = momentZone.weekday();
              let days = image.shedule.split(";");

              if(parseInt(days[currentDay]) == 0)
              {
                return true; 
              }
            }
            return <SwiperSlide key={index} className={`img_flyer ${orientation}`}><a href={image.action != null ? image.action : '#' } >{typeof image.url != 'object' ? <img src={image.url} title={image.title} /> : image.url }</a></SwiperSlide>
          })}
        </Swiper>

        <ButtonCustom color='lightred' style={{height:'auto'}}><img src="/shedule.png" style={{height:'30px'}}/> {(status == null || status ? <Horarios data={schedule} /> : 'Pista Cerrada')}</ButtonCustom>
        {/*<ButtonCustom color='yellow' href="https://search.google.com/local/writereview?placeid=ChIJqxHVYqTZiZYR4fATu6iPGVw">Ayudanos con tu opinion!</ButtonCustom>*/}
        <ButtonCustom color='green' href="./promo"><img src="/promo2.png" style={{height:'30px'}}/><span>Ofertas diarias</span></ButtonCustom>
        <ButtonCustom color='blue' href="./media"><img src="/gallery.png" style={{height:'30px'}}/> Video e Imagenes</ButtonCustom>

        <iframe id="id_description_iframe" className="rte-zone" height="200" frameBorder={0} src="https://www.racefacer.com/en/karting-tracks/chile/belloto" />
        {/*<IconGoTo href='https://www.portalbelloto.cl/' target='_blank' src="./logo_pb2.png" className='logo_pb' />*/}
      </div>
   )
}
