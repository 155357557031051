

const FooterMenu = () => {
    return (<footer>
        <nav className='navigation_buttons'>
          <a href="https://www.rallykart.cl/sucursal/portal-belloto"> <img src="/favicon.png"/>Sucursal</a>
          <a href="https://www.instagram.com/rallykartbelloto/" className='instagram'><img src="/instagram2.png"/>Instagram</a>
          <a href="https://wa.me/56990634327" target='_blank' className='whatsapp'><img src="/whatsapp2.png"/>Whatsapp</a>
          <a href="mailto:belloto@rallykarting.cl" target='_blank' className='mailbox'><img src="/mailbox2.png"/>Contacto</a>
          {/*<a href="https://search.google.com/local/writereview?placeid=ChIJqxHVYqTZiZYR4fATu6iPGVw" target='_blank'><img src="./review.png"/>Reseñas</a>*/}
        </nav>
      </footer>)
};

export default FooterMenu;