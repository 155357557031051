import ButtonCustom from "../components/buttoncustom"

const downloads_apps = "/flyer/flyer4.png"

const btn_store_button = "/btn_app_store.png"
const btn_google_play = "/btn_google_play.png"

export { Apps_Download }
const Apps_Download = () => {
    return (<div style={{display:'flex', flexDirection: 'column', height: '100vh', justifyContent:'center'}}>
        <div style={{padding:'10px 10px 5px 10px', backgroundColor:'white', borderRadius: '10px'}} >
            <img src={downloads_apps} style={{width:'100%', borderRadius: '10px'}} />
        </div>
        <div style={{backgroundColor:'white', borderRadius: '10px',"position":"relative","display":"flex","alignItems":"center","flexWrap":"wrap","alignContent":"center","justifyContent":"center", marginTop: '15px', padding: '40px 0 35px'}}>
            <ButtonCustom color='blue' href="https://apps.apple.com/us/app/racefacer/id1021242902" style={{padding:'0px',width: '50%', height: '65px', backgroundColor: 'transparent', boxShadow: 'none'}}><img src={btn_store_button} style={{width:"100%", height: "100%"}} /></ButtonCustom>
            <ButtonCustom color='blue' href="https://play.google.com/store/apps/details?id=com.faceracer&hl=es&gl=US" style={{padding:'0px',width: '50%', height: '65px', backgroundColor: 'transparent', boxShadow: 'none'}}><img src={btn_google_play} style={{width:"100%", height: "100%"}} /></ButtonCustom>
        </div>
    </div>)
}