import { useEffect, useState } from "react";
import { images, url_api_res, url_websocket } from '../constants/constants';

export { PromoPage}
const PromoPage = () => {
    const [promos, setPromos] = useState([]);

    useEffect(() => {

        (async () => {
            return await fetch(`${url_api_res}/getlist_promos`).then(response => response.json() ).then(res => {
                if(res.success) {
                    setPromos(res.data)
                }
            }).catch((e) => console.log(e.message));
        })()

    },[])

    return (<div>

        <div className='titlebar'>
            <h3 style={{color:'white',textTransform:'uppercase'}}>Promociones</h3>
        </div>

        <div style={{display:'flex', flexWrap:'wrap', height:'90vh', justifyContent:'center', alignContent:'flex-start', alignItems:'center', padding: '10px 5px'}} >
            {promos.length != 0 && promos.map((promo, index) => {
                return <div key={index} style={{backgroundColor: promo.color && promo.color || 'black',borderRadius:'10px',marginBottom:'5px',color:'white',padding:'10px',textAlign:'left'}}>
                    {promo.title}
                </div>
            }) || <div style={{color:'white'}}>No hay promociones disponibles.</div>}
        </div>

    </div>);
}