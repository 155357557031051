import './App.css';
import { io } from "socket.io-client";

// import the library
import { library } from '@fortawesome/fontawesome-svg-core'

// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

import FooterMenu from './components/footermenu';
import Header from './components/header';

import { Outlet, Route, Routes } from 'react-router-dom';

import { Home } from './views/home.view';
import { NotFoundPage } from './views/notfoundpage.view';
import { Apps_Download } from './views/download.view';
import { MediaPage } from './views/media.view';
import { PromoPage } from './views/promo.view';
import { ViewFlyer } from './views/viewflyer.view';


function App() {


  // Sucursal
  if(window.location.pathname == '/')
  {
    window.location.href = '/belloto/';
  }else{
    if((window.location.pathname.match(new RegExp("/", "g")) || []).length <= 1)
    {
      window.location.href = window.location.href + '/';
    }
  }

  let appdisabled = false;
  if(appdisabled)
    return <>App deshabilitada</>

  return (
    <div className="App">
      <Header/>
      <main>
        <Routes>
          <Route path="/:sucursal/" element={<Outlet />}>
            <Route index element={<Home />} />
            <Route path="apps_download" element={<Apps_Download />} />
            <Route path="media" element={<MediaPage />} />
            <Route path="promo" element={<PromoPage />} />
            <Route path="viewflyer/:flyer" element={<ViewFlyer />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </main>
      <FooterMenu />
      <SpeedInsights />
      <Analytics />
    </div>
  );
}
export default App;
library.add(fab, fas, far);