


const ButtonCustom = ({color, href, children, style}) => {
    const goTo = (url) => {
        if(url != null) document.location.href = url;
    }
    return (
        <div className={`button-custom ${color}`} style={style} onClick={(event) => { goTo(href); }}>
          {children}
        </div>
    )
}

export default ButtonCustom;